import React, {Component, Fragment} from "react";
import {Container, ContainerProps, Row, Col, Button, UncontrolledTooltip} from "reactstrap";
import ReactMarkdown from "react-markdown";
import {MetaTags} from "react-meta-tags";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";

import {NavBar} from "../components/NavBar";
import {Footer} from "../components/Footer";
import {Utils} from "../Utils";

import "../assets/css/commands.css";

interface IState {
  commands: ContainerProps | undefined;
  refreshComponent: ContainerProps | undefined;
  footerFix: string | undefined;
}

interface IModule {
  name: string;
  aliases: string;
  commands: ICommand[];
  submodules: IModule[];
}

interface ICommand {
  aliases: string;
  description: string;
  usages: string[];
  examples: string[];
  isOwnerOnly: boolean;
  memberPermissions: string;
  botPermissions: string;
  context: string;
}

export class Commands extends Component<{}, IState> {
  
  private loadingContainer =
    <Container className="commands-refresh d-flex justify-content-center">
      <FontAwesomeIcon icon={faSyncAlt} size="2x" color="#dc143c" className="refresh-icon"/>
    </Container>;
  
  private fixBottom = "fix-bottom";
  
  constructor(props: Readonly<{}>) {
    super(props);

    this.state = {
      commands: undefined,
      refreshComponent: this.loadingContainer,
      footerFix: this.fixBottom,
    }
    this.getCommands();
  }
  
  getCommands() {
    fetch("/api/commands")
      .then(async response => {
        if (!response.ok)
          throw new Error(response.statusText)
        
        let commands = await response.json();
        this.setState({
          commands: this.renderCommands(commands["modules"]),
          refreshComponent: undefined,
          footerFix: undefined
        });
      })
  }

  renderCommands(modules: IModule[]) {
    return (
      <Container className="commands">
        <h5>Before to read the commands remember this:<br/><b>owner only</b> - only the owner of RiasBot can use this command. It is not related to a server owner.</h5>
        {modules.map((module, index) =>
          <Fragment key={index}>
            {this.renderModuleCommands(module, index)}
            {module.submodules?.map((submodule, index) => this.renderModuleCommands(submodule, index))}
          </Fragment>
        )}
        <UncontrolledTooltip placement="right" target="command-owner-only">
          This command can be used only by the owner of RiasBot.
        </UncontrolledTooltip>
      </Container>
    )
  }
  
  renderModuleCommands(module: IModule, index: number) {
    return (
      <div className="module" key={index}>
        <p className="title">{Utils.titleize(module.name)}</p>
        <hr className="line"/>
        {module.commands.map((command, index) => {
          return (
            <div className="command" key={index}>
              <p className="aliases d-flex">{command.aliases.split(" ").map(alias => {
                alias = module.aliases !== undefined && module.aliases.length !== 0 
                    ? `r!${module.aliases.split(" ")[0]} ${alias}`
                    : `r!${alias}`;
                return alias;
              }).join(" / ") ?? `r!${module.name}`}
              {command.isOwnerOnly
                ? <Button className="owner-only-badge text-center btn-sm" id="command-owner-only" disabled>Owner only</Button>
                : null}
              </p>
              <div className="description">
                <ReactMarkdown children={command.description}/>
              </div>
              <Row>
                <Col md="6">
                  <div className="d-flex">
                    <p className="examples-label">Examples:</p>
                    <div className="examples">
                      <ReactMarkdown children={command.examples.map(remark => remark.replace("{0}", "r!")).join("\n")}/>
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="d-flex">
                    <p className="usages-label">Usages:</p>
                    <div className="usages">
                      <ReactMarkdown children={command.usages.map(remark =>
                          remark.replace("{0}", "r!")
                              .replace("[", "\\[")
                              .replace("]", "\\]")).join("\n")}/>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>RiasBot - Commands</title>
          <meta name="description" content="I have around 150 commands categorized in modules and submodules easy to use."/>

          <meta itemProp="name" content="RiasBot - Commands"/>
          <meta itemProp="description" content="I have around 150 commands categorized in modules and submodules easy to use."/>

          <meta property="og:url" content={`${process.env.PUBLIC_URL}/commands`}/>
          <meta property="og:title" content="RiasBot - Commands"/>
          <meta property="og:description" content="I have around 150 commands categorized in modules and submodules easy to use."/>

          <meta name="twitter:url" content={`${process.env.PUBLIC_URL}/commands`}/>
          <meta name="twitter:title" content="RiasBot - Commands"/>
          <meta name="twitter:description" content="I have around 150 commands categorized in modules and submodules easy to use."/>
        </MetaTags>
        <NavBar navbarClass={"navbar-normal"}/>
        {this.state.refreshComponent}
        {this.state.commands}
        <Footer fixed={this.state.footerFix}/>
      </Fragment>
    )
  }
}