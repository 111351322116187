import React, {Component, Fragment} from "react";
import {Container, Card, CardBody, CardTitle, CardText, CardFooter, Row, Col, UncontrolledTooltip, Tooltip,
  Button, ContainerProps, FormGroup, Label, Input, CustomInput} from "reactstrap";
import {ChromePicker, ColorResult} from "react-color";
import {MetaTags} from "react-meta-tags";
import {parse, parseEmbedTitle, parseAllowLinks} from "../components/markdown";
import {Utils} from "../Utils";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

import {NavBar} from "../components/NavBar";
import {Footer} from "../components/Footer";

import "../assets/css/embed.css";
import riasAvatar from "../assets/images/rias_avatar.jpg";

interface ICommand {
  [key: string]: {} | undefined;
}

interface IField {
  name?: string;
  value?: string;
  inline?: boolean;
}

interface IState {
  date: string;
  showColorPicker: boolean;
  color?: string;
  colorPreview?: string;
  content?: string;
  contentPreview?: string;
  title?: string;
  titlePreview?: string;
  description?: string;
  descriptionPreview?: string;
  thumbnail?: string;
  thumbnailPreview?: string;
  image?: string;
  imagePreview?: string;
  fields?: IField[];
  embedFields?: ContainerProps[];
  fieldsPreview?: ContainerProps[];
  command: string;
  commandJson: ICommand;
  commandRadio: number;
  showJsonCopied: boolean;
  warning?: string;
}

export class Embed extends Component<{}, IState> {
  private colorHexAlphabet = "0123456789abcdef";
  private avatar = "https://cdn.discordapp.com/avatars/381387277764395008/2746314902291da2ac17bac6254de474.png?size=1024";

  private updateTimeInterval: NodeJS.Timeout | undefined;
  private timeMinutesNow = 0;
  
  constructor(props: Readonly<{}>) {
    super(props);
    
    let date = new Date();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    this.timeMinutesNow = minutes;
    
    this.state = {
      date: `Today at ${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`,
      showColorPicker: false,
      color: "#dc143c",
      colorPreview: "#dc143c",
      title: "User joined",
      titlePreview: "User joined",
      description: "**Koneko#0001** joined the server!",
      descriptionPreview: "**Koneko#0001** joined the server!",
      thumbnail: "%avatar%",
      thumbnailPreview: this.avatar,
      command: "r!greetmsg ",
      commandJson: {
        color: "#dc143c",
        title: "User joined",
        description: "**Koneko#0001** joined the server!",
        thumbnail: "%avatar%"
      },
      commandRadio: 2,
      showJsonCopied: false
    }
    
    this.updateTime = this.updateTime.bind(this);
    this.toggleColorPicker = this.toggleColorPicker.bind(this);
    this.closeColorPicker = this.closeColorPicker.bind(this);
    this.changeColor = this.changeColor.bind(this);
    this.changeStateColor = this.changeStateColor.bind(this);
    
    this.inputContentChanged = this.inputContentChanged.bind(this);
    this.inputTitleChanged = this.inputTitleChanged.bind(this);
    this.inputDescriptionChanged = this.inputDescriptionChanged.bind(this);
    this.inputThumbnailChanged = this.inputThumbnailChanged.bind(this);
    this.inputImageChanged = this.inputImageChanged.bind(this);
    this.inputFieldNameChanged = this.inputFieldNameChanged.bind(this);
    this.inputFieldValueChanged = this.inputFieldValueChanged.bind(this);
    this.inputFieldInlineChanged = this.inputFieldInlineChanged.bind(this);
    this.commandRadioChanged = this.commandRadioChanged.bind(this);
    
    this.addEmbedField = this.addEmbedField.bind(this);
    this.removeEmbedField = this.removeEmbedField.bind(this);
    this.renderFields = this.renderFields.bind(this);
    this.copyJsonToClipboard = this.copyJsonToClipboard.bind(this);
    

  }

  componentDidMount() {
    this.updateTimeInterval = setInterval(this.updateTime, 1000);
  }

  componentWillUnmount() {
    if (this.updateTimeInterval) {
      clearInterval(this.updateTimeInterval);
    }
  }

  updateTime() {
    let date = new Date();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    
    if ((minutes === 0 && this.timeMinutesNow === 59) || minutes > this.timeMinutesNow) {
      this.timeMinutesNow = minutes;
      this.setState({
        date: `Today at ${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`,
      });
    }
  }

  toggleColorPicker(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.stopPropagation();
    this.setState({
      showColorPicker: true
    });
  }

  closeColorPicker() {
    this.setState({
      showColorPicker: false
    });
  }

  changeColor(color: ColorResult) {
    this.changeStateColor(color.hex);
  };
  
  changeStateColor(color: string) {
    let command = this.state.commandJson ?? {} as ICommand;
    
    let tempColor = undefined;

    if (color === "random") {
      let roll = () => Math.floor(Math.random() * this.colorHexAlphabet.length);
      tempColor = "#" + this.colorHexAlphabet[roll()] + this.colorHexAlphabet[roll()] + this.colorHexAlphabet[roll()]
                      + this.colorHexAlphabet[roll()] + this.colorHexAlphabet[roll()] + this.colorHexAlphabet[roll()];
    }
    
    if (color.trim() === "")
      command.color = undefined;
    else
      command.color = color !== "random" && !color.startsWith("#") ? "#" + color : color;

    this.setState({
      color: color,
      colorPreview: tempColor ?? color,
      commandJson: command
    });
  }
  
  inputContentChanged(event: React.ChangeEvent<HTMLInputElement>) {
    let command = this.state.commandJson ?? {} as ICommand;
    
    if (event.target.value.trim() === "")
      command.content = undefined;
    else
      command.content = event.target.value;
    
    this.setState({
      content: event.target.value,
      contentPreview: this.replacePlaceholders(event.target.value),
      commandJson: command
    });
  }

  inputTitleChanged(event: React.ChangeEvent<HTMLInputElement>) {
    let command = this.state.commandJson ?? {} as ICommand;

    if (event.target.value.trim() === "")
      command.title = undefined;
    else
      command.title = event.target.value;

    this.setState({
      title: event.target.value,
      titlePreview: this.replacePlaceholders(event.target.value),
      commandJson: command
    });
  }

  inputDescriptionChanged(event: React.ChangeEvent<HTMLInputElement>) {
    let command = this.state.commandJson ?? {} as ICommand;

    if (event.target.value.trim() === "")
      command.description = undefined;
    else
      command.description = event.target.value;

    this.setState({
      description: event.target.value,
      descriptionPreview: this.replacePlaceholders(event.target.value),
      commandJson: command
    });
  }

  inputThumbnailChanged(event: React.ChangeEvent<HTMLInputElement>) {
    let command = this.state.commandJson ?? {} as ICommand;

    if (event.target.value.trim() === "")
      command.thumbnail = undefined;
    else
      command.thumbnail = event.target.value;

    this.setState({
      thumbnail: event.target.value,
      thumbnailPreview: event.target.value.toLowerCase() === "%avatar%" ? this.avatar : event.target.value,
      commandJson: command
    });
  }

  inputImageChanged(event: React.ChangeEvent<HTMLInputElement>) {
    let command = this.state.commandJson ?? {} as ICommand;

    if (event.target.value.trim() === "")
      command.image = undefined;
    else
      command.image = event.target.value;

    this.setState({
      image: event.target.value,
      imagePreview: event.target.value.toLowerCase() === "%avatar%" ? this.avatar : event.target.value,
      commandJson: command
    });
  }
  
  inputFieldNameChanged(event: React.ChangeEvent<HTMLInputElement>, id: number) {
    let command = this.state.commandJson ?? {} as ICommand;

    let fields = this.state.fields!;
    let commandFields = command.fields as IField[];
    if (event.target.value.trim() === "") {
      fields[id].name = undefined;
      commandFields[id].name = undefined;
    }
    else {
      fields[id].name = this.replacePlaceholders(event.target.value);
      commandFields[id].name = event.target.value;
    }

    this.setState({
      fields: [...fields],
      commandJson: command
    });

    this.renderFields();
  }

  inputFieldValueChanged(event: React.ChangeEvent<HTMLInputElement>, id: number) {
    let command = this.state.commandJson ?? {} as ICommand;

    let fields = this.state.fields!;
    let commandFields = command.fields as IField[];
    if (event.target.value.trim() === "") {
      fields[id].value = undefined;
      commandFields[id].value = undefined;
    }
    else {
      fields[id].value = this.replacePlaceholders(event.target.value);
      commandFields[id].value = event.target.value;
    }
    
    this.setState({
      fields: [...fields],
      commandJson: command
    });

    this.renderFields();
  }

  inputFieldInlineChanged(event: React.ChangeEvent<HTMLInputElement>, id: number) {
    let command = this.state.commandJson ?? {} as ICommand;

    let fields = this.state.fields!;
    fields[id].inline = event.target.checked;

    let commandFields = command.fields as IField[];
    commandFields[id].inline = event.target.checked ? true : undefined;

    this.setState({
      fields: [...fields],
      commandJson: command
    });

    this.renderFields();
  }

  commandRadioChanged(index: number) {
    let command = ""

    switch (index) {
      case 1:
        command = "";
        break;
      case 2:
        command = "r!greetmsg "
        break;
      case 3:
        command = "r!byemsg "
        break;
      case 4:
        command = "r!xpm "
        break;
      case 5:
        command = "r!xpmr "
        break;
    }

    this.setState({
      command: command,
      commandRadio: index
    })
  }
  
  addEmbedField() {
    let fields = this.state.fields;
    if (fields !== undefined && fields.length === 25)
      return;
    
    if (fields === undefined)
      fields = new Array<IField>();
    
    let embedFields = this.state.embedFields;
    if (embedFields === undefined) {
      embedFields = new Array<ContainerProps>();
    }

    let fieldDiv = <div className="field" key={embedFields.length}>
      <div className="d-flex">
        <FormGroup>
          <Input type="text" placeholder="" id="nameInput" maxLength={256} onChange={e => this.inputFieldNameChanged(e, embedFields!.length)}/>
          <Label for="nameInput">Name</Label>
        </FormGroup>
        <FormGroup className="inline-check" check>
          <CustomInput type="switch" id={`embedFieldInline${embedFields!.length}`} label="Inline" onChange={e => this.inputFieldInlineChanged(e, embedFields!.length)}/>
        </FormGroup>
      </div>
      <FormGroup>
        <Input className="value-input" type="textarea" placeholder="" id="valueInput" maxLength={1024} onChange={e => this.inputFieldValueChanged(e, embedFields!.length)}/>
        <Label for="valueInput">Value</Label>
      </FormGroup>
    </div>;
    
    let command = this.state.commandJson;
    let commandFields = command.fields as IField[];
    if (commandFields === undefined) {
      commandFields = new Array<IField>(1);
      commandFields[0] = {} as IField;
      command.fields = commandFields;
    } else {
      commandFields.push({} as IField);
    }

    this.setState({
      fields: [...fields, {} as IField],
      embedFields: [...embedFields, fieldDiv],
      commandJson: command
    });
  }
  
  removeEmbedField() {
    let fields = this.state.fields;
    if (fields === undefined)
      return;

    fields.splice(fields.length - 1, 1);

    let embedFields = this.state.embedFields;
    if (embedFields !== undefined)
      embedFields.splice(embedFields.length - 1, 1);

    let fieldsPreview = this.state.fieldsPreview;
    if (fieldsPreview !== undefined)
      fieldsPreview.splice(fieldsPreview.length - 1, 1);

    let command = this.state.commandJson;
    let commandFields = command.fields as IField[];
    commandFields.splice(commandFields.length - 1, 1);
    if (commandFields.length === 0)
      command.fields = undefined;
    
    this.setState({
      fields: fields.length === 0 ? undefined : [...fields],
      embedFields: embedFields !== undefined && embedFields.length !== 0 ? [...embedFields] : undefined,
      fieldsPreview: fieldsPreview !== undefined && fieldsPreview.length !== 0 ? [...fieldsPreview] : undefined,
      commandJson: command,
      warning: fields.length === 0 ? undefined : this.state.warning
    });
  }
  
  renderFields() {
    let warning: string | undefined;
    let fields = this.state.fields!;
    let fieldsPreview = new Array<ContainerProps>(fields.length);
    
    for (let i = 0; i <fields.length;) {
      if (i + 2 < fields.length && fields[i].inline && fields[i + 1].inline && fields[i + 2].inline) {
        let name1 = this.state.fields![i].name;
        let value1 = this.state.fields![i].value;
        fieldsPreview[i] = this.renderField(i, name1, value1, "1/5");

        let name2 = this.state.fields![i + 1].name;
        let value2 = this.state.fields![i + 1].value;
        fieldsPreview[i + 1] = this.renderField(i + 1, name2, value2, "5/9");

        let name3 = this.state.fields![i + 2].name;
        let value3 = this.state.fields![i + 2].value;
        fieldsPreview[i + 2] = this.renderField(i + 2, name3, value3, "9/13");
        
        if ((name1 === undefined || value1 === undefined
          || name2 === undefined || value2 === undefined
          || name3 === undefined || value3 === undefined)
          && warning === undefined) {
          warning = "You have empty field names or values. Please fill them or remove them.";
        }
        
        i += 3;
      } else if (i + 1 < fields.length && fields[i].inline && fields[i + 1].inline) {
        let name1 = this.state.fields![i].name;
        let value1 = this.state.fields![i].value;
        fieldsPreview[i] = this.renderField(i, name1, value1, "1/7");

        let name2 = this.state.fields![i + 1].name;
        let value2 = this.state.fields![i + 1].value;
        fieldsPreview[i + 1] = this.renderField(i + 1, name2, value2, "7/13");

        if ((name1 === undefined || value1 === undefined
          || name2 === undefined || value2 === undefined)
          && warning === undefined) {
          warning = "You have empty field names or values. Please fill them or remove them.";
        }

        i += 2;
      } else {
        let name = this.state.fields![i].name;
        let value = this.state.fields![i].value;
        fieldsPreview[i] = this.renderField(i, name, value, "1/7");

        if ((name === undefined || value === undefined) && warning === undefined) {
          warning = "You have empty field names or values. Please fill them or remove them.";
        }
        
        i += 1;
      }
    }
    
    this.setState({
      fieldsPreview: [...fieldsPreview],
      warning: warning
    })
  }
  
  renderField(key: number, name: string | undefined, value: string | undefined, gridColumn: string) {
    return <div key={key} className="embed-field" style={{gridColumn: gridColumn}}>
      <div className="embed-field-name">{parseEmbedTitle(name)}</div>
      <div className="embed-field-value text-pre text-break">{parseAllowLinks(value)}</div>
    </div>;
  }
  
  replacePlaceholders(content: string) {
    return Utils.replace(content, {
      "%member%": "Rias#4357",
      "%user%": "Rias#4357",
      "%server%": "Gremory Castle",
      "%guild%": "Gremory Castle",
      "%members%": "420",
      "%avatar%": this.avatar,
      "%level%": "69",
      "%role%": "devil"
    });
  }
  
  copyJsonToClipboard() {
    if (this.state.warning !== undefined)
      return;
    
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData?.setData('text/plain', this.state.command + JSON.stringify(this.state.commandJson));
      e.preventDefault();
      document.removeEventListener('copy', () => null);
    });
    document.execCommand('copy');

    this.setState({
      showJsonCopied: true
    })
    setTimeout(() => this.setState({
      showJsonCopied: false
    }), 2000);
  }

  render() {
    return(
      <Fragment>
        <MetaTags>
          <title>Rias - Embed Generator</title>
          <meta name="description" content="Use this generator to generate embed messages for the greetmessage, byemesage, xpmesage and xprewardmesage commands."/>

          <meta itemProp="name" content="Rias - Embed Generator"/>
          <meta itemProp="description" content="Use this generator to generate embed messages for the greetmessage, byemesage, xpmesage and xprewardmesage commands."/>

          <meta property="og:url" content={`${process.env.PUBLIC_URL}/embed`}/>
          <meta property="og:title" content="Rias - Embed Generator"/>
          <meta property="og:description" content="Use this generator to generate embed messages for the greetmessage, byemesage, xpmesage and xprewardmesage commands."/>

          <meta name="twitter:url" content={`${process.env.PUBLIC_URL}/embed`}/>
          <meta name="twitter:title" content="Rias - Embed Generator"/>
          <meta name="twitter:description" content="Use this generator to generate embed messages for the greetmessage, byemesage, xpmesage and xprewardmesage commands."/>
        </MetaTags>
        <div className="embed-main" onClick={this.closeColorPicker}>
          <NavBar navbarClass={"navbar-normal"}/>
          <Container fluid>
            <Row className="top">
              <Col className="embed" lg="6">
                <Card>
                  <CardBody>
                    <CardTitle>Modify any element of the embed</CardTitle>
                    <FormGroup>
                      <Input className="content-input" id="contentInput" type="textarea" placeholder="" maxLength={2000} onChange={this.inputContentChanged}/>
                      <Label for="contentInput">Message</Label>
                    </FormGroup>
                    <div className="d-flex">
                      <div className="color-wrapper d-flex">
                        <div className="color-preview" style={{backgroundColor: this.state.colorPreview}} onClick={this.toggleColorPicker}/>
                        <FormGroup>
                          <Input type="text" placeholder="" value={this.state.color} maxLength={7} onChange={e => this.changeStateColor(e.target.value)}/>
                          <Label for="colorInput">Color</Label>
                        </FormGroup>
                      </div>
                      <FormGroup>
                        <Input type="text" placeholder="" id="titleInput" value={this.state.title} maxLength={256} onChange={this.inputTitleChanged}/>
                        <Label for="titleInput">Title</Label>
                      </FormGroup>
                    </div>
                    <div className="color-picker" style={{display: this.state.showColorPicker ? "block" : "none"}} onClick={e => e.stopPropagation()}>
                      <ChromePicker color={this.state.colorPreview} onChangeComplete={this.changeColor}/>
                    </div>
                    <FormGroup>
                      <Input className="description-input" id="descriptionInput" type="textarea" placeholder="" value={this.state.description} maxLength={2048} onChange={this.inputDescriptionChanged}/>
                      <Label for="descriptionInput">Description</Label>
                    </FormGroup>
                    <FormGroup>
                      <Input type="text" placeholder="" value={this.state.thumbnail} maxLength={512} onChange={this.inputThumbnailChanged}/>
                      <Label for="thumbnailInput">Thumbnail</Label>
                    </FormGroup>
                    <FormGroup>
                      <Input type="text" placeholder="" value={this.state.image} maxLength={512} onChange={this.inputImageChanged}/>
                      <Label for="imageInput">Image</Label>
                    </FormGroup>
                    {this.state.embedFields}
                    <Button color="primary add-field-button" onClick={this.addEmbedField}>Add field</Button>
                    <Button color="primary remove-field-button" onClick={this.removeEmbedField}>Remove field</Button>
                  </CardBody>
                  <CardFooter>Placeholders (hover or click on them for more details):<br/>
                    <code id="mentionPlaceholder">%mention%</code>,&nbsp;
                    <code id="memberPlaceholder">%member% or %user%</code>,&nbsp; 
                    <code id="guildPlaceholder">%server% or %guild%</code>,&nbsp;
                    <code id="membersPlaceholder">%members%</code>,&nbsp;
                    <code id="avatarPlaceholder">%avatar%</code>,&nbsp;
                    <code id="levelPlaceholder">%level%</code>,&nbsp;
                    <code id="rolePlaceholder">%role%</code>,&nbsp;
                    <code id="roleMentionPlaceholder">%role_mention%</code>
                  </CardFooter>
                  <UncontrolledTooltip placement="bottom" target="mentionPlaceholder" fade={true}>will mention the member</UncontrolledTooltip>
                  <UncontrolledTooltip placement="bottom" target="memberPlaceholder" fade={true}>will write the member's username</UncontrolledTooltip>
                  <UncontrolledTooltip placement="bottom" target="guildPlaceholder" fade={true}>will write the name of the server</UncontrolledTooltip>
                  <UncontrolledTooltip placement="bottom" target="membersPlaceholder" fade={true}>will write the server member count</UncontrolledTooltip>
                  <UncontrolledTooltip placement="bottom" target="avatarPlaceholder" fade={true}>will write the member's avatar url, use it in the embed's thumbnail or image</UncontrolledTooltip>
                  <UncontrolledTooltip placement="bottom" target="levelPlaceholder" fade={true}>will write the member's reached level</UncontrolledTooltip>
                  <UncontrolledTooltip placement="bottom" target="rolePlaceholder" fade={true}>will write the rewarded role's name</UncontrolledTooltip>
                  <UncontrolledTooltip placement="bottom" target="roleMentionPlaceholder" fade={true}>will write the rewarded role's mention</UncontrolledTooltip>
                </Card>
              </Col>
              <Col className="preview" lg="6">
                <Card>
                  <CardBody>
                    <CardTitle>Check how the embed looks</CardTitle>
                    <div className="message d-flex">
                      <img className="avatar" src={riasAvatar} alt="avatar"/>
                      <div className="contents">
                        <div>
                          <span className="username">Rias</span>
                          <span className="bot-tag">
                            <FontAwesomeIcon className="bot-tag-verified" icon={faCheck} color="white"/>
                            <span className="bot-text">BOT</span>
                          </span>
                          <span className="timestamp text-muted">{this.state.date}</span>
                        </div>
                        <div className="text-pre text-break">{parse(this.state.contentPreview)}</div>
                        <div className="embed-wrapper d-flex justify-content-start align-self-start">
                          <div className="embed-message" style={{borderLeft: `4px ${this.state.colorPreview} solid`}}>
                            <div className="embed-grid">
                              <div className="embed-title embed-margin">{parseEmbedTitle(this.state.titlePreview)}</div>
                              <div className="embed-description embed-margin text-pre text-break">{parseAllowLinks(this.state.descriptionPreview)}</div>
                              <img className="embed-thumbnail" style={{display: this.state.thumbnailPreview !== undefined && this.state.thumbnailPreview.trim() !== "" ? "block" : "none"}}
                                   src={this.state.thumbnailPreview} alt=""/>
                              {this.state.fieldsPreview !== undefined 
                                ? <div className="embed-fields">
                                    {this.state.fieldsPreview}
                                  </div>
                                : null
                              }
                              {this.state.imagePreview !== undefined && this.state.imagePreview.trim() !== "" 
                                ? <img className="embed-image" style={{gridColumn: this.state.thumbnailPreview !== undefined && this.state.thumbnailPreview.trim() !== "" ? "1/3" : "1/1"}}
                                     src={this.state.imagePreview} alt=""/> 
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Container className="bottom" fluid>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <FormGroup>
                      <Label>Copy the json code and paste in the command you need</Label>
                      <div>
                        <CustomInput id="noneCommand" type="radio" label="None" inline checked={this.state.commandRadio === 1} onChange={() => this.commandRadioChanged(1)}/>
                        <CustomInput id="greetCommand" type="radio" label="Greet" inline checked={this.state.commandRadio === 2} onChange={() => this.commandRadioChanged(2)}/>
                        <CustomInput id="byeCommand" type="radio" label="Bye" inline checked={this.state.commandRadio === 3} onChange={() => this.commandRadioChanged(3)}/>
                        <CustomInput id="xpCommand" type="radio" label="Xp" inline checked={this.state.commandRadio === 4} onChange={() => this.commandRadioChanged(4)}/>
                        <CustomInput id="xprrCommand" type="radio" label="Xp role reward" inline checked={this.state.commandRadio === 5} onChange={() => this.commandRadioChanged(5)}/>
                      </div>
                    </FormGroup>
                    <div>
                      <Button color="primary" id="button-copy" onClick={this.copyJsonToClipboard}>Copy</Button>
                      <Tooltip placement="bottom" target="button-copy" trigger="click" fade={true} isOpen={this.state.showJsonCopied}>Copied</Tooltip>
                    </div>
                  </div>
                  <CardText>{this.state.warning === undefined
                    ? this.state.command + JSON.stringify(this.state.commandJson) 
                    : this.state.warning}</CardText>
                </CardBody>
              </Card>
            </Container>
          </Container>
          <Footer/>
        </div>
      </Fragment>
    )
  }
}