import React, {Component, Fragment} from "react";
import { Link } from "react-router-dom";
import {Container, Row, Col, Button} from "reactstrap";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCrown, faUserCircle, faSearch, faFire, faStar, faCog} from "@fortawesome/free-solid-svg-icons";

import {NavBar} from "../components/NavBar";
import {HomeTop} from "../components/HomeTop";
import {Footer} from "../components/Footer";
import {MetaTags} from "react-meta-tags";

import "../assets/css/home.css";
import waifusImage from "../assets/images/waifus.png";
import profileImage from "../assets/images/profile.png";
import discordServerIcon from "../assets/images/discord_server_icon.png";
import githubLogo from "../assets/images/github.png";
import patreonLogo from "../assets/images/patreon.png";

interface IState {
  navbarClass: string;
  patronsComponent: any;
}

export class Home extends Component<{}, IState> {
  
  private crimson: string = "#dc143c";

  constructor(props: Readonly<{}>) {
    super(props);

    this.changeNavBar = this.changeNavBar.bind(this);
    this.state = {
      navbarClass: "navbar-transparent",
      patronsComponent: null
    };
    
    this.renderPatrons()
  }

  changeNavBar(navbarClass: string) {
    this.setState({
      navbarClass: navbarClass
    })
  }
  
  renderPatrons() {
    fetch(`/api/patreon`)
      .then(async response => {
        if (!response.ok)
          throw new Error(response.statusText)

        let patrons = await response.json()
        if (patrons.length === 0)
        {
          this.setState({
            navbarClass: this.state.navbarClass,
            patronsComponent: null
          })
        } else {
          this.setState({
            navbarClass: this.state.navbarClass,
            patronsComponent: (
              <Fragment>
                <Row className="supporters-title justify-content-center">
                  <p className="text-center">People who support RiasBot ❤</p>
                </Row>
                <Row className="supporters justify-content-center">
                  {patrons.map((patron: any, id: number) =>
                    <Col key={id} md="3" lg="2" className="name d-flex flex-column align-items-center">
                      <img className="avatar" src={patron.discord_avatar} alt={patron.patreon_username}/>
                      <p className="name text-center" style={{color: patron.tier < 5 ? "white" : "crimson"}}>
                        {patron.patreon_username}<br/>{patron.discord_username}#{patron.discord_discriminator}
                      </p>
                    </Col>
                  )}
                </Row>
              </Fragment>
            )
          })
        }
      });
  }

  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>Rias - Discord Bot</title>
          <meta name="description" content="A cute Discord Bot that you will enjoy using."/>
          
          <meta itemProp="name" content="Rias - Discord Bot"/>
          <meta itemProp="description" content="A cute Discord Bot that you will enjoy using."/>
          
          <meta property="og:url" content={process.env.PUBLIC_URL}/>
          <meta property="og:title" content="Rias - Discord Bot"/>
          <meta property="og:description" content="A cute Discord Bot that you will enjoy using."/>

          <meta name="twitter:url" content={process.env.PUBLIC_URL}/>
          <meta name="twitter:title" content="Rias - Discord Bot"/>
          <meta name="twitter:description" content="A cute Discord Bot that you will enjoy using."/>
        </MetaTags>
        <NavBar navbarClass={this.state.navbarClass} fixed="top"/>
        <HomeTop navbarCallback={this.changeNavBar}/>
        <Container fluid className="home">
          <Container className="intro-container d-flex justify-content-center">
            <p className="intro text-center">DISCOVER THE FEATURES</p>
          </Container>
          <Container className="features">
            <Container className="feature">
              <div className="d-flex flex-row justify-content-center title">
                <FontAwesomeIcon icon={faCrown} size="2x" color={this.crimson}/>
                <h2>Build your harem</h2>
              </div>
              <img className="waifus img-fluid" src={waifusImage} alt="waifus"/>
              <p className="waifus-description text-center">Claim any waifu you want from anime using hearts. Complete your harem and show it to the others. You can make a waifu special or create one just for yourself.<br/>
              Popular claimed waifus get more expensive.</p>
            </Container>
            <Container>
              <Row className="d-flex flex-column flex-md-row">
                <Col className="d-flex justify-content-center justify-content-md-end" xs={{order: 2}} md={{size: 6, order: 1}}>
                  <img className="profile" src={profileImage} alt="profile"/>
                </Col>
                <Col className="d-flex flex-row justify-content-center justify-content-md-start" xs={{order: 1}} md={{size: 6, order: 2}}>
                  <FontAwesomeIcon icon={faUserCircle} size="2x" color={this.crimson}/>
                  <div className="description">
                    <h2>Show yourself</h2>
                    <p>Complete your profile.<br/>
                      Buy a background cover.<br/>
                      Change its color.<br/>
                      Make it beautiful.</p>
                  </div>
                </Col>
              </Row>
            </Container>
            <Row>
              <Col sm="6" className="d-flex flex-row">
                <FontAwesomeIcon icon={faSearch} size="2x" color={this.crimson}/>
                <div className="description">
                  <h2>Anime, manga and characters searching</h2>
                  <p>You can search any anime, manga or character using their names or IDs from AniList. Show your favourites to others.</p>
                </div>
              </Col>
              <Col sm="6" className="d-flex flex-row col-small-screen">
                <FontAwesomeIcon icon={faFire} size="2x" color={this.crimson}/>
                <div className="description">
                  <h2>Entertain the new users</h2>
                  <p>Set a greet message for the new users and/or a bye one to show the others that the ones who left didn't know the value of your server.<br/>
                  You can also set an auto-assignable role for evey new person so that he/she can feel a part of the family.</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="6" className="d-flex flex-row">
                <FontAwesomeIcon icon={faStar} size="2x" color={this.crimson}/>
                <div className="description">
                  <h2>Server xp, leveling and role rewards</h2>
                  <p>Your active users receive XP for their activity. You can set some roles to be rewarded at certain levels.</p>
                </div>
              </Col>
              <Col sm="6" className="d-flex flex-row">
                <FontAwesomeIcon icon={faCog} size="2x" color={this.crimson}/>
                <div className="description">
                  <h2>Server moderation</h2>
                  <p>You can kick, ban, warn, set a warning punishment, or mute a user for a certain time for their bad behaviour. All these can be logged in a channel as well.</p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="more">
            <Row>
              <Col sm="6" className="d-flex flex-column align-items-center">
                <p className="info text-center">I have around 150 commands categorized in modules and submodules easy to use.</p>
                <Button color="primary" tag={Link} to="/commands">Check them here</Button>
              </Col>
              <Col sm="6" className="d-flex flex-column align-items-center">
                <p className="info text-center">Vote me on <a href="https://top.gg/bot/381387277764395008/vote">Discord Bot List (top.gg)</a> to become more popular.
                You can vote every 12 hours and you will also receive hearts.</p>
                <a href="https://top.gg/bot/381387277764395008/vote">
                  <img src="https://top.gg/api/widget/381387277764395008.svg" alt="Rias" />
                </a>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <p className="info text-center">Join <a href="https://rias.gg/discord">Gremory Castle</a>.
                It's more than a support server for me, it's a beautiful and cool community.</p>
              </Col>
              <Col md="4">
                <p className="info text-center">The project is also open-source on GitHub. Self-hosting tutorials are not available.</p>
              </Col>
              <Col md="4">
                <p className="info text-center"><a href="https://www.patreon.com/riasbot">Support this project!</a><br/>
                  I'm running 24/7 on a dedicated server. This server is not cheap and it provides high resources for me to run smooth and fast.</p>
              </Col>
            </Row>
            <Row>
              <Col md="4" className="d-flex flex-column align-items-center">
                <a href="https://rias.gg/discord">
                  <img className="discord-server" src={discordServerIcon} alt="server"/>
                </a>
              </Col>
              <Col md="4" className="d-flex flex-column align-items-center">
                <a href="https://github.com/FenikkusuKoneko/RiasBot">
                  <img className="github" src={githubLogo} alt="github"/>
                </a>
              </Col>
              <Col md="4" className="d-flex flex-column align-items-center">
                <a href="https://www.patreon.com/riasbot">
                  <img className="patreon" src={patreonLogo} alt="patreon"/>
                </a>
              </Col>
            </Row>
            {this.state.patronsComponent}
          </Container>
        </Container>
        <Footer credits={true}/>
      </Fragment>
    )
  }
}