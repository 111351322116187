import React, {Component} from "react";
import {Container} from "reactstrap";
import {MetaTags} from "react-meta-tags";

export class Privacy extends Component {
  render() {
    return (
      <Container style={{marginTop: "50px"}}>
        <MetaTags>
          <title>RiasBot - Privacy Policy</title>
        </MetaTags>
        <h1 style={{color: "#DC143C", marginBottom: "30px"}}>Privacy Policy</h1>
        <p>By inviting Rias Bot (ID 831993597166747679) in your server and by using it, you agree with the following privacy policy.</p>
        <br/>
        <h4 style={{color: "#DC143C"}}>Data Collection</h4>
        <p>
          The data is stored in an PostgreSQL server database that can be accessed only by Rias Bot, rias.gg and the developers of these applications.
          Rias Bot does not store the content of your messages, your username, or your nicknames in the servers. The only relevant info stored is your ID, your server IDs, your server member IDs and other public information your add, like customizing your profile.
        </p>
        <br/>
        <h4 style={{color: "#DC143C"}}>Data Removal</h4>
        <p>You may require the deletion of your data. Your profile, your waifus and custom characters will be deleted. The xp you gathered in every server where the bot is present cannot be deleted on your request. This xp can be deleted by any server administrator by using the provided commands of the bot.</p>
        <br/>
        <h4 style={{color: "#DC143C"}}>Tracking</h4>
        <p>Your activity in a server is tracked by the bot using your messages. As said above, the content of these messages are not stored in the database, but they are used for a properly function of certain commands and to gather xp in the servers you and the bot share.</p>
        <br/>
        <h4 style={{color: "#DC143C"}}>Questions</h4>
        <p>For any question feel free to join the support server.</p>

        <p>The privacy policy can be changed at any moment and this action will be notified to the users.</p>
      </Container>
    )
  }
}