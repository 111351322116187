export class Utils {
  static titleize(str: string) {
    let strings = str.toLowerCase().split(" ");
    for (let i = 0; i < strings.length; i++) {
      strings[i] = strings[i].charAt(0).toUpperCase() + strings[i].slice(1);
    }
    return strings.join(' ');
  }

  static replace(source : string, replacements : { [name: string]: string }) {
    return source.replace(  new RegExp("%([A-z]*)%", "g"), m => replacements[m] ?? m);
  }
}