import React, {Component} from "react";
import {Route, Switch} from "react-router";
import {Home} from "./views/Home";
import {Commands} from "./views/Commands";
import {Embed} from "./views/Embed";
import {Terms} from "./views/Terms";
import {Privacy} from "./views/Privacy";

import "./assets/css/app.css";

export class App extends Component {

  render() {
    return (
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/commands' component={Commands} />
          <Route path='/embed' component={Embed} />
          <Route path='/terms' component={Terms} />
          <Route path='/privacy' component={Privacy} />
        </Switch>
    );
  }
}

export default App;
