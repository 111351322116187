import React, {Component} from "react";
import {Container, Row, Col} from "reactstrap";
import {NavLink} from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";

import "../assets/css/footer.css";

interface IProps {
    credits?: boolean
    fixed?: string;
}

export class Footer extends Component<IProps> {

  renderCredits() {
    if (this.props !== undefined && this.props.credits)
      return (<Row className="credits">
        <Col className="credit d-flex flex-column align-items-center" md="4">
          <h3>Rias avatar</h3>
          <h5>Created by LIKAUCE: <a href="https://www.pixiv.net/en/users/9833906">pixiv link</a></h5>
        </Col>
        <Col className="credit d-flex flex-column align-items-center" md="4">
          <h3>Server icon</h3>
          <h5 className="text-center">Created by dchristian796: <a href="https://www.deviantart.com/dchristian796">deviantart link</a></h5>
        </Col>
        <Col className="credit d-flex flex-column align-items-center" md="4">
          <h3>Koneko avatar</h3>
          <h5>Created by Miyama-Zero: <a href="https://miyama0.dragon.gr.jp/">profile link</a></h5>
        </Col>
      </Row>)

    return null;
  }

  render() {
    return (
      <footer className={this.props.fixed}>
          <Container className="footer" fluid>
            {this.renderCredits()}
              <Row className="contact">
                  <Col className="d-flex flex-column align-items-center" md="6">
                      <div className="d-flex flex-row justify-content-center title">
                          <FontAwesomeIcon icon={faDiscord} size="2x" color="white"/>
                          <h5 className="info">Koneko#0001</h5>
                      </div>
                  </Col>
                  <Col className="d-flex flex-column align-items-center" md="6">
                      <div className="d-flex flex-row justify-content-center title">
                          <FontAwesomeIcon icon={faEnvelope} size="2x" color="white"/>
                          <a className="info" href = "mailto: fenikkusukoneko@gmail.com">fenikkusukoneko@gmail.com</a>
                      </div>
                  </Col>
              </Row>
              <div className="d-flex justify-content-center" style={{columnGap: "30px", paddingBottom: "10px"}}>
                <NavLink to={'/terms'}>Terms of Service</NavLink>
                <NavLink to={'/privacy'}>Privacy Policy</NavLink>
              </div>
          </Container>
      </footer>
    );
  }
}