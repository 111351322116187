import React, {Component} from "react";
import {Container} from "reactstrap";
import "../assets/css/home-top.css";

import riasAvatar from "../assets/images/rias_avatar.jpg";

interface IProps {
  navbarCallback: any;
}

export class HomeTop extends Component<IProps> {

  constructor (props: IProps) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll() {
    this.props.navbarCallback(window.pageYOffset > 10 ? "navbar-normal" : "navbar-transparent");
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <Container fluid className="home-top">
        <Container className="rias-avatar-container d-flex justify-content-center">
          <img className="rias-avatar align-self-center" src={riasAvatar} alt="Rias"/>
        </Container>
        <Container className="intro d-flex flex-column align-items-center">
          <h1>Hi there, I am Rias</h1>
          <h4 className="sub-title">A cute Discord bot that you will enjoy using</h4>
        </Container>
      </Container>
    )
  }
}