import React, {Component} from "react";
import { Container, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faChevronDown} from "@fortawesome/free-solid-svg-icons";

import "../assets/css/nav-bar.css";

interface IProps {
  navbarClass: string;
  fixed?: string;
}

interface IState {
  collapsed: boolean;
}

export class NavBar extends Component<IProps, IState> {

  constructor (props: IProps) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <header>
        <Navbar className={`box-shadow mb-3 ${this.props.navbarClass}`} fixed={this.props.fixed} expand="lg">
          <Container>
            <NavbarBrand tag={Link} to="/">Rias - Discord Bot</NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar}>
              {
                this.state.collapsed
                  ? <FontAwesomeIcon icon={faBars} size="2x" color="white"/>
                  : <FontAwesomeIcon icon={faChevronDown} size="2x" color="white"/>
              }

            </NavbarToggler>
            <Collapse isOpen={!this.state.collapsed} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink tag={Link} to="/commands">Commands</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/embed">Embed Generator</NavLink>
                </NavItem>
                <NavItem>
                  <a className="nav-link" href="https://discordapp.com/api/oauth2/authorize?client_id=381387277764395008&permissions=1609952503&scope=bot">Invite RiasBot</a>
                </NavItem>
                <NavItem>
                  <a className="nav-link" href="https://rias.gg/discord">Join Gremory Castle</a>
                </NavItem>
                <NavItem>
                  <a className="nav-link" href="https://www.patreon.com/riasbot">Become a Patron</a>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    )
  }
}